import React from 'react';
import '../css/Topbar.css';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Fab from '@material-ui/core/Fab';
import Card from "@material-ui/core/Card";
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PersonIcon from '@mui/icons-material/Person';
import ListItemText from "@material-ui/core/ListItemText";
import { useNavigate } from "react-router-dom";
import authUtil from '../utils/AuthUtils';
import LogoutIcon from '@mui/icons-material/Logout';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';


const useStyles = makeStyles(theme => ({
  flexGrow0: {
      flexGrow: 0,
      marginRight:10,
  },
  wrapper: {
      position: "relative"
  },
  paper: {
      position: "absolute",
      top: 56,
      marginRight:40,
      right: -24,
      maxHeight: 400,
      padding: theme.spacing(1),
      padding: 2,
      overflow: "scroll",
  },
  card: {
      minWidth: 100,
      height: "100%"
  },
}));

const Topbar = () => {
  const clientName = localStorage.getItem('_clientName');
  const classes = useStyles();
  const [openuser, setOpenuser] = React.useState(false);
  const navigate = useNavigate();

  const handleClickAway = () => {
    setOpenuser(false);
  };

  const handleClickLogout = () => {
    authUtil.clearSession();
    navigate('/');
  };

  const handleClick = (event) => {
    setOpenuser(prev => !prev);
  };

  return (
    <div className="topbar">
      <div className="topbar-content">
        <img
          src="https://cargoes-platform-prod-images-cdn.azureedge.net/cargoes-prod-images/assets/images/Cargoes_logo_RGB_Positive.svg"
          alt="Logo"
          className="logo"
        />
      </div>
      <div className={classes.flexGrow0}>
          <ClickAwayListener onClickAway={handleClickAway}>
          <div className={classes.wrapper}>
              <IconButton
                  className={classes.flexGrow0}
                  aria-label="user-settings"
                  color="inherit"
                  size="medium"
                  onClick={handleClick}
              >
                  <Fab size="medium" aria-label="add" style={{ fontSize: '15px', color: "#173a64", background: "white", fontWeight: "500", height: "45px", width: "45px" }}>
                      <h3 >{clientName ? clientName[0] : "N"} </h3>
                  </Fab>
              </IconButton>
              {openuser ? (
              <div className={classes.paper}>
                  <Card className={classes.card}>
                      {
                          clientName ? 
                          <div>
                              <MenuItem>
                                  <ListItemIcon>
                                      <PersonIcon fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText><b>{clientName}</b></ListItemText>
                              </MenuItem>
                          </div>
                            : 
                            <div>
                              <MenuItem>
                                  <ListItemIcon>
                                      <CorporateFareIcon fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText><b>{"Not Found"}</b></ListItemText>
                              </MenuItem>
                          </div>
                      }
                      <MenuItem onClick={handleClickLogout}>
                          <ListItemIcon>
                              <LogoutIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText><b>Logout</b></ListItemText>
                      </MenuItem>
                  </Card>
              </div>
              ) : null}
          </div>
          </ClickAwayListener>
      </div>
    </div>
  );
};

export default Topbar;
