import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import useStyles from '../styles/ManageLocationsStyles';
import TableSortLabel from "@material-ui/core/TableSortLabel";

const TableComponent = ({ rows, columns, manageColumns, handleMenuClick, circularBackdrop, handleRequestSort, orderBy, order }) => {
  const classes = useStyles();
  const createNoTable = () => {
    let arr = [];
    arr.push(
      <TableRow style={{ height: '60px' }}>
        <TableCell colSpan="11" align="center" className={classes.noRecordsRow}>
          {circularBackdrop ? 'Fetching data, please wait' : 'No records found'}
        </TableCell>
      </TableRow>
    );
    return arr;
  };
  return (
    <div className={classes.tableWrapper}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => {
              if (manageColumns[column.id] || column.id === 'actions') {
                return (
                <TableCell 
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  {column.sortable ? (
                  <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={handleRequestSort(column.id)}
                  >
                      {column.label}
                      {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                      ) : null}
                  </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>);
              }
              return null; // Hide columns that are toggled off
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length
            ? rows.map((row, index) => {
                return (
                  <TableRow hover role="data-row" key={index}>
                    {columns.map(column => {
                      if (manageColumns[column.id] || column.id === 'actions') {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} width={column.width}>
                            {column.id === 'actions' ? (
                              <IconButton onClick={event => handleMenuClick(event, row)}>
                                <MoreVertIcon />
                              </IconButton>
                            ) : (
                              value || '-'
                            )}
                          </TableCell>
                        );
                      }
                      return null;
                    })}
                  </TableRow>
                );
              })
            : createNoTable()}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableComponent;
