import jwtDecode from 'jwt-decode'; // The actual named function is jwtDecode

// Authentication utility function
const authUtil = {
  setSession: response => {
    const data = response.data;
    const token = data.token;
    const clientName = data.clientName;

    // Saves user token to localStorage
    localStorage.setItem('_token', token);
    localStorage.setItem('_clientName', clientName);
    //set expiry time
    const decoded = jwtDecode(token);
    localStorage.setItem('_expiry', decoded.exp);
  },

  updateToken: newToken => {
    const decoded = jwtDecode(newToken);
    localStorage.setItem('_token', newToken);
    localStorage.setItem('_expiry', decoded.exp);
  },

  getToken: () => {
    if (authUtil.isAuthenticated()) {
      return localStorage.getItem('_token');
    } else {
      return '';
    }
  },

  isAuthenticated: () => {
    const token = localStorage.getItem('_token');
    if (token === null || token === undefined) {
      return false;
    } else {
      return !authUtil.isTokenExpired();
    }
  },

  isTokenExpired: () => {
    try {
      const expiry = parseInt(localStorage.getItem('_expiry'), 10);
      return expiry < Math.floor(Date.now() / 1000);
    } catch (err) {
      return false;
    }
  },

  //clear tokens and localstorage
  clearSession: () => {
    localStorage.clear();
  }
};

export default authUtil;
