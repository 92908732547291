import { makeStyles } from '@material-ui/core/styles';
import theme from '../themes/lightTheme';

const currentTheme = { palette: { primary: { main: '#123456' } }, direction: 'ltr' };
const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawerContainer: {
    display: 'flex',
    marginTop: theme.mixins.toolbar.height // Match this with Topbar height
  },
  drawerOpen: {
    width: drawerWidth,
    background: currentTheme.palette.primary.main,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    overflowX: 'hidden',
    width: '80px',
    background: currentTheme.palette.primary.main,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: 55
  },
  displayContents: {
    display: 'contents',
    textDecoration: 'none',
    color: 'white'
  },
  toolbar: theme.mixins.toolbar
}));

export default useStyles;
