import React from 'react';
import { InputBase, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../styles/ManageLocationsStyles';

const SearchBar = ({ searchKey, handleSearch, clearSearchText, handleFilterButtonClick }) => {
  const classes = useStyles();
  return (
    <div>
      <InputBase
        className={classes.input}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search template' }}
        value={searchKey.value}
        onChange={handleSearch}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={
          searchKey.value.length > 0 ? (
            <InputAdornment position="end">
              <IconButton size="small" onClick={clearSearchText}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
    </div>
  );
};

export default SearchBar;
