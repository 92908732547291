import React from 'react';
import { TablePagination } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const userPrefs = {
  rowsPerPageOptions: [10, 25, 100]
};

const PaginationComponent = ({
  queryCount,
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage
}) => {
  const [t, i18n] = useTranslation();
  return (
    <TablePagination
      rowsPerPageOptions={userPrefs.rowsPerPageOptions}
      component="div"
      count={queryCount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      backIconButtonProps={{
        'aria-label': 'previous page'
      }}
      nextIconButtonProps={{
        'aria-label': 'next page'
      }}
      labelRowsPerPage={t('Rows per page')}
    />
  );
};

export default PaginationComponent;
