import React from 'react';
import { makeStyles, MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import { Typography, InputLabel } from '@material-ui/core';
import { Input, FormHelperText } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import notify from "../../utils/notifier";
import AuthService from '../../services/AuthService';
import authUtil from '../../utils/AuthUtils';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '80px 48px 48px',
        ['@media only screen and (max-width:600px)']: {
            width: "100%",
            minWidth: "100%",
            maxWidth: "100%"
        }
    },
    loginText: {
        fontFamily: 'Helvetica Neue',
        fontSize: '21px',
        lineHeight: '20px',
        color: 'rgba(0,0,0,.87)',
        fontWeight: '500'
    },
    resetText: {
        fontFamily: 'Helvetica Neue',
        fontSize: '21px',
        lineHeight: '20px',
        marginLefft: '5px',
        color: 'rgba(0,0,0,.87)',
        fontWeight: '500'
    },
    form: {
        paddingTop: '32px'
    },
    forgot_password_align: {
        textAlign: "right",
        fontSize: '12px',
        alignItems: "right",
        float: 'right'
    },
    emailId: {
        fontSize: '14px',
        lineHeight: '20px',
        fontFamily: 'Helvetica Neue',
    },
    secret: {
        fontSize: '14px',
        lineHeight: '20px',
        fontFamily: 'Helvetica Neue',
        marginBottom: '22px'
    },
    drawerButton: {
        margin: theme.spacing(2),
        float: "right",

    },
    formContainer: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(1)
    },
    list: {
        width: "25vw",
    },
    flexItem: {
        flex: 1
    },
    sidedrawerFooter: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#ffffff",
    },
    noSignin: {
        lineHeight: 'normal',
        fontFamily: 'Helvetica Neue',
        fontSize: '14px',
        margin: '16px auto',
        width: '100%',
        height: '36px',
        textTransform: 'none',
        color: '#a09595',
        backgroundColor: 'lightgray',
    },
    signin: {
        lineHeight: 'normal',
        fontFamily: 'Helvetica Neue',
        fontSize: '14px',
        margin: '16px auto',
        width: '100%',
        height: '36px',
        textTransform: 'none',
        color: '#ffffff',
        backgroundColor: '#3c4252',
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: '#3c4252'
        }
    },
    failedMsg: {
        fontFamily: 'Helvetica Neue',
        fontSize: '14px',
        color: 'red'
    },
    warningStyleNoBg: {
        // color: "#721C24",
        textAlign: "center",
        fontSize: '10px',
        // padding: '1px 3px 4px',
        marginLeft: "10px",
        marginBottom: "10px"
    },
    warningStyle: {
        //width: "100%",
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        padding: '1px 3px 4px',
        marginLeft: "-10px",
    },
    drawerHeader: {
        padding: theme.spacing(2),
    },
    paswordValidity: {
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        padding: '1px 3px 4px',
        marginLeft: "1px",
        marginTop: "2px"
    },
    dialogTitle: {
        fontWeight: "700",
        fontSize: "1.25rem"
    },
    dialogContentText: {
        fontWeight: "700"
    },
    dialogButton: {
        fontWeight: "700",
        fontFamily: "sans-serif"
    },
    input: {
        background: "rgb(232, 241, 250)"
    },
    root1: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function LoginForm(props) {

    const classes = useStyles();
    const theme = useTheme();
    const [clientId, setClientId] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [autoFocus, setAutoFocus] = React.useState(true);
    const [resetPassword, setResetPassword] = React.useState(false);
    const [userNotFound, setUserNotFound] = React.useState(false);
    const [backdrop, setBackdrop] = React.useState(false);
    const [newPasswordCheck, setNewPasswordCheck] = React.useState("");
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [loginInProgress, setLoginInProgress] = React.useState(false);
    const [loginFailed, setLoginFailed] = React.useState(false);
    const [apiUserLoginErr, setApiUserLoginErr] = React.useState(false);
    const [userRole, setUserRole] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [values, setValues] = React.useState({
        //password: '',
        organizationId: '',
        valid: false,
        disable: true
    });
    const navigate = useNavigate();


    const SHOW_RECAPTCHA = 3 //Add recaptcha on login form after 3 failed attempts
    React.useEffect(() => {
        setAutoFocus(true);
    }, []);

    const setInputFocus = event => {
        if (!autoFocus) {
            setAutoFocus(true);
        }
        return true;
    };

    const closeDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setResetPassword(open);
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleLogin()
        }
    };
    const handleClientIdChange = event => {
        setClientId(event.target.value);
        handleValidationChange(event);
    };
    const handleValidationChange = event => {
        setValues({
            disable: false,
            valid: true
        });
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    //TODO: validate password is required
    const handlePasswordChange = event => {
        setPassword(event.target.value);
    };

    function validateClientId(elementValue) {
        return /^[A-Za-z0-9@#$&_*+]*$/.test(elementValue);
    }

    function flagClientId() {
        if (clientId.length === 0) {
            return false
        }
        if (password.length === 0) {
            return /^[A-Za-z0-9@#$&*+]*$/.test(clientId);
        } else {
            return !validateClientId(clientId)
        }
    }


    const handleLogin = () => {
        setLoginInProgress(true);
        setLoginFailed(false);
        setApiUserLoginErr(false)
        setUserNotFound(false);
        setUserRole('');
        if (validateClientId(clientId)) {
            AuthService.login(clientId, password)
                .then(response => {
                    if (response && response.data) {
                        authUtil.setSession(response);
                        
                        navigate('/');
                    } else {
                        setLoginFailed(true);
                    }
                    setLoginInProgress(false);
                }).catch(error => {
                    setLoginInProgress(false);
                    if (error.response) {
                        const { status, data } = error.response;

                        if (data && data.errorCode === '40330') {
                            setUserNotFound(true);
                        } else if (status === 403) {
                            setApiUserLoginErr(true);
                        } else {
                            setLoginFailed(true);
                        }
                    } else if (error.message === 'Network Error') {
                        notify({
                            type: 'error',
                            message: 'Internal Server Error'
                        });
                    }
                });
        } else {
            setLoginInProgress(false);
            setLoginFailed(true);
        }
    }


    const handleOpenPopupOpen = () => {
        setPopupOpen(true);
    }

    const handleClosePopup = () => {
        setPopupOpen(false);
    }

    const handleCloseDrawer = () => {
        setResetPassword(false);
    }

    const sideList = side => (
        <div className={classes.list}>
            <div >
                <div className={classes.drawerHeader}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleCloseDrawer}
                        edge="start"
                    >
                        <ChevronRightIcon color="primary" />
                    </IconButton>
                </div>
            </div>
            <Backdrop open={backdrop} style={{ color: "primary", zIndex: 100 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    );

    const disableLoginButton = () => {
        if (clientId === '' || password === '') {
            return true
        }
        if (!validateClientId(clientId)) {
            return true;
        }
        return false;
    }

    const isClientIdValid = () => {
        if (validateClientId(clientId) && password.length > 0) {
            return true
        }
        return false;
    }

    return (
        <MuiThemeProvider theme={theme} >
            <div className={classes.drawerHeader}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.toggleDrawer(false)}
                    edge="start"
                >
                    <ChevronRightIcon />
                </IconButton>
            </div>
            <div className={classes.root}>
                <Typography className={classes.loginText}>LOGIN TO YOUR ACCOUNT</Typography>
                <div className={classes.form}>
                    <div className={classes.emailId}>
                        {/* <FormControl fullWidth> */}
                        <InputLabel>Client Name *</InputLabel>
                        <Input
                            autoFocus={setInputFocus()}
                            value={clientId}
                            type={'text'}
                            label={'Your Client Name *'}
                            onChange={handleClientIdChange}
                            // error={flagClientId()}
                            fullWidth
                        />
                        <FormHelperText style={{ color: '#f44336', paddingBottom: '10px' }}>{values.valid ? '' : ''}</FormHelperText>
                        {/* </FormControl> */}
                    </div>
                </div>

                <div className={classes.secret}>
                    <InputLabel>Password *</InputLabel>
                    <TextField
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        value={password}
                        onChange={handlePasswordChange}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        tabIndex={- 1}
                                        className={classes.root1}
                                    >
                                        {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            className: classes.input
                        }}
                    />
                </div>
                <Button
                    className={isClientIdValid() ? classes.signin : classes.noSignin}
                    disabled={disableLoginButton() || loginInProgress}
                    onClick={handleLogin}
                >
                    Login
                </Button>

                <Button
                    size="small"
                    color="secondary"
                    className={classes.forgot_password_align}
                    onClick={handleOpenPopupOpen}
                >
                    forgot password?
                </Button>
                <br></br>
                <Typography className={classes.failedMsg} style={{ display: loginFailed ? 'inline-block' : 'none' }} >
                    Incorrect client name or password.
                </Typography>
                <Typography className={classes.failedMsg} style={{ display: apiUserLoginErr ? 'inline-block' : 'none' }} >
                    API users are not authorized to login
                </Typography>
                <Typography className={classes.failedMsg} style={{ display: userNotFound ? 'inline-block' : 'none' }} >
                    This account does not exist.
                </Typography>
                <Drawer
                    anchor="right"
                    open={resetPassword}
                    onOpen={closeDrawer('right', true)}
                    onClose={closeDrawer('right', false)}
                >
                    {sideList('right')}
                </Drawer>
                <Dialog
                    open={popupOpen}
                    onClose={handleClosePopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                        <Typography className={classes.dialogTitle} gutterBottom>
                            {`Forgot Password`}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.dialogContentText}>
                            {"Please contact your admin for resetting your password. You can email on supportdatachain.cargoescom@cargoes.com"}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus className={classes.dialogButton} color="primary" onClick={handleClosePopup} >
                            {"CLOSE"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </MuiThemeProvider>
    );

}