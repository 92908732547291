import { toast } from "react-toastify";

const SUCCESS = "success";
const ERROR = "error";
const INFO = "info";
const WARN = "warn"

export default function notify(obj) {
  switch (obj.type) {
    case SUCCESS:
      toast.success(obj.message);
      break;
    case ERROR:
      toast.error(obj.message);
      break;
    case INFO:
      toast.info(obj.message);
      break;
    case WARN:
      toast.warn(obj.message);
      break;
    default:
      toast.info(obj.message);
  }
}
