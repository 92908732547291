import { makeStyles } from '@material-ui/core/styles';
import theme from '../themes/lightTheme';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%', // Occupy 90% of the page width
    height: 'calc(100vh - 160px)', // Occupy 80% of the viewport height
    backgroundColor: '#FFFFFF', // White background for the container
    // padding: theme.spacing(5), // Padding for inner spacing
    borderRadius: '10px', // Rounded corners for soft appearance
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)', // Subtle shadow
    textAlign: 'center', // Center the text inside
    display: 'flex',
    flexDirection: 'column', // To align the content vertically
    justifyContent: 'center', // Vertically center the content
    alignItems: 'center'
  }
}));

export default useStyles;
