import axios from 'axios';
const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  function (config) {
    config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function login(clientId, clientSecret) {
    const requestObj = JSON.stringify({
        clientName: clientId,
        clientSecret: clientSecret
    });
    return axios.post(BASE_API_URL + '/v2/token', requestObj, {
        headers: {
            "Content-Type": "application/json"
        }
    });
};

export default {
  login
};
