/* eslint-disable react/prop-types */
import React from 'react';
import { Toolbar, Box } from '@mui/material';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import theme from '../themes/lightTheme';

const ExternalLayout = props => {
  const { children } = props;
  return (
  <div>
  <Topbar />
  <div style={{ backgroundColor: '#F2F2F2', height: '100vh' }}>
    <Sidebar/>
    <Box component="main" sx={{ml: '80px', height: 'calc(100vh - 120px)', marginTop: '60px'}}>
        {children}
    </Box>
  </div>
</div>
  );
};

export default ExternalLayout;
