import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const FilterDialog = ({
  open,
  handleClose,
  handleApplyFilters,
  renderFilters,
  handleFilterClear
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: { padding: '20px' } // Adds some padding inside the dialog
      }}
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Filters
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {renderFilters()}
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end', padding: '16px 24px' }}>
        <Button
          onClick={handleFilterClear}
          variant="outlined"
          color="primary"
          style={{ marginRight: '10px' }}
        >
          Clear
        </Button>
        <Button onClick={handleApplyFilters} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
