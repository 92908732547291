import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = createMuiTheme({
  primaryColor: "#fff",
  secondaryColor: "#0000",
  primaryBordercolor: "#00000024",
  typography: {
    useNextVariants: true,
    fontFamily: "auto",
    color: "#006db3"
  },
  palette: {
    primary: {
      light: "#f50057",
      main: "#006db3" //f50057
    }
  }
});
let lightTheme = responsiveFontSizes(theme);
export default lightTheme;
