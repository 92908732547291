import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ManageColumnsDialog = ({ open, handleClose, manageColumns, handleToggleColumn }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: { padding: '20px' }
      }}
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Manage Columns
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <p>Changes made here are auto saved.</p>
        <Grid container direction="column" spacing={2}>
          {Object.keys(manageColumns).map(columnKey => (
            <Grid item key={columnKey}>
              <FormControlLabel
                control={
                  <Switch
                    checked={manageColumns[columnKey]}
                    onChange={handleToggleColumn(columnKey)}
                    color="primary"
                  />
                }
                label={columnKey.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                labelPlacement="start"
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ManageColumnsDialog;
