import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import Layout from './components/Layout';
import ManageLocations from './components/ManageLocations/ManageLocationsPage';
import LocationDetails from './components/ManageLocations/LocationDetails/LocationDetailsPage';
import AddressDetails from './components/ManageLocations/AddressDetails/AddressDetailsPage';
import Onboard from './components/Onboard/OnboardPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProtectedRoute } from './utils/ProtectedRoutes';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Onboard />} />
        <Route
          path="*"
          element={
            <Layout>
              <ToastContainer />
              <Routes>
                <Route path="/" element={<ProtectedRoute element={HomePage} />} />
                <Route path="/locations" element={<ProtectedRoute element={ManageLocations} />} />
                <Route
                  path="/locations/locations/:id"
                  element={<ProtectedRoute element={LocationDetails} />}
                />
                <Route
                  path="/locations/addresses/:id"
                  element={<ProtectedRoute element={AddressDetails} />}
                />
                <Route path="/addresses" element={<ProtectedRoute element={ManageLocations} />} />
                <Route path="/zones" element={<ProtectedRoute element={ManageLocations} />} />
              </Routes>
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}
