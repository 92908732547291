import axios from 'axios';
import Util from '../utils/Util';
import authUtil from '../utils/AuthUtils';
import notify from '../utils/notifier';

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;
const TOKEN_HEADER = 'x-location-service-integration-token';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  function (config) {
    config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
    config.headers[TOKEN_HEADER] = authUtil.getToken();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401 && window.location.pathname !== '/login') {
      notify({
        type: 'warn',
        message: 'Session timed out, please login again.',
        autoClose: 7000,
        limit: 1
      });
      setTimeout(() => {
        window.location.href = '/login';
        authUtil.clearSession();
      }, 1000);
    } else {
      return Promise.reject(error);
    }
  }
);

function getAddressData(query){
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v2/addresses/search` + queryParams);
}

function getLocationsData(query) {
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v3/locations` + queryParams);
}

function getCountriesData() {
  return axios.get(BASE_API_URL + `/v3/countries`);
}

function getStatesData(query) {
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v2/states` + queryParams);
}

function getCitiesData(query) {
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v2/cities` + queryParams);
}

export default {
  getAddressData,
  getLocationsData,
  getCountriesData,
  getStatesData,
  getCitiesData
};
