import axios from 'axios';
const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;

async function getApplicationNamespaces() {
  const data = await axios.get(BASE_API_URL + '/v2/application-namespace', {
      headers: {
          "Content-Type": "application/json"
      }
  });
  let applicationNamespaces = []
  if(data.data.applicationNamespaces){
    data.data.applicationNamespaces.forEach(element => {
      if(element.is_active)
      {
        applicationNamespaces.push(element.name)
      }
    });
  }
  return applicationNamespaces;
};

export default getApplicationNamespaces;
