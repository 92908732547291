import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import authUtil from './AuthUtils';
import notify from './notifier';

export const ProtectedRoute = ({ element: Component, ...rest }) => {
  const location = useLocation();
  if (authUtil.isAuthenticated()) {
    return <Component {...rest} />;
  } else {
    notify({
      type: 'warn',
      message: 'Session timed out, please login again.',
      autoClose: 7000,
      limit: 1
    });

    // Redirect to the login page with a state for the redirection source
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};
