import { makeStyles } from '@material-ui/core/styles';
import theme from '../themes/lightTheme';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: '#f9f9f9',
    padding: theme.spacing(2),
    overflow: 'auto',
    marginRight: theme.spacing(3),
    overflow: 'auto'
  },
  tableWrapper: {
    width: '100%',
    maxHeight: '55vh',
    overflow: 'auto',
    marginTop: theme.spacing(2),
    background: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    '@media (max-height: 950px)': {
      maxHeight: '50vh', // Reduced for smaller screen heights
    },
    '@media (max-height: 900px)': {
      maxHeight: '45vh', // Reduced for smaller screen heights
    },
    '@media (max-height: 800px)': {
      maxHeight: '35vh', // Reduced for smaller screen heights
    },
    '@media (max-height: 700px)': {
      maxHeight: '30vh', // Reduced for smaller screen heights
    },
    '@media (max-height: 600px)': {
      maxHeight: '25vh', // Further reduced for very small screens
    },
    '@media (max-height: 500px)': {
      maxHeight: '20vh', // Further reduced for very small screens
    }
  },

  tableTitle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  tableHeadRow: {
    fontWeight: '700'
  },
  tableHeadRight: {
    float: 'right',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 700px)': { // Media query for small screens
      flexDirection: 'column',     // Stack vertically on small screens
      alignItems: 'flex-start',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  input: {
    marginRight: theme.spacing(2),
    flex: 1,
    height: '38px',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    width: '400px',
    marginTop: '10px',
    marginLeft: '10px',
    '@media (max-width: 1000px)': {
      width: '300px', // Reduced for smaller screen heights
    },
    '@media (max-width: 850px)': {
      width: '200px', // Reduced for smaller screen heights
    },
    '@media (max-width: 750px)': {
      width: '150px', // Reduced for smaller screen heights
    },
    backgroundColor: '#ffffff',
    boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  progressBar: {
    width: '35%',
    marginTop: theme.spacing(1)
  },
  text: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '1.2rem', // Larger text for better readability
    color: theme.palette.primary.contrastText // Theme color for text
  },
  card: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    boxShadow: '0 2px 4px rgba(0,0,0,0.12)' // Added shadow to cards
  },
  addButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#4CAF50', // Green color for add button
    color: 'white',
    '&:hover': {
      backgroundColor: '#45A045' // Darker green on hover
    }
  },
  header: {
    paddingBottom: theme.spacing(1),
    borderBottom: `3px solid ${theme.palette.primary.main}`
  },
  headerText: {
    fontWeight: '1000',
    fontSize: '24px',
    color: 'black',
    fontFamily: 'Montserrat'
  },
  flexItem: {
    flex: 1
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    marginBottom: theme.spacing(1)
  },
  textField: {
    paddingRight: theme.spacing(2),
    marginTop: 0,
    minWidth: '500px'
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  formControl: {
    paddingRight: '2rem',
    minWidth: '21rem',
    maxWidth: '18rem',
    marginTop: theme.spacing(3),
    paddingBottom: '0px'
  },
  ackIdtext: {
    marginTop: '0'
  },
  paper: {
    width: '45%',
    color: theme.secondaryColor,
    background: theme.primaryColor
  },
  inputBox: {
    marginTop: '0',
    marginLeft: theme.spacing(3)
  },
  paper_root2_1: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-around'
  },
  priorityField: {
    margin: '16px 32px 0 0'
  },
  tableContainer: {
    padding: '20px',
    paddingTop: '0px',
    paddingLeft: '5px'
  },
  tableTitle: {
    float: 'left',
    paddingLeft: '20px'
  },
  tableBorder: {
    border: '0.8px solid #00000021'
  },
  addButtonCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right'
  },
  characterCount: {
    fontSize: '12px',
    color: '#999',
    fontWeight: 'light'
  }
}));

export default useStyles;
